import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { API_PARAM_FIELDS, ApiParamFields } from 'constants/api';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { Category } from 'types/Category';
import { categoryKeys } from '.';
import { DEFAULT_QUERY, NUMBER_OF_PAGE_RESULTS } from '../../constants/search';
import {
  fetchCategory,
  fetchCategoryInfoLinks,
  fetchCategoryPromotedBrands,
  fetchCategorySilo,
  fetchCategorySubCategories,
  getCategoryInfoUsingPath,
  searchCategory,
  SearchCategoryParams,
} from './connector';

const useCategoryInfoFromPath = (enabled = true) => {
  const {
    locale,
    query: { slug },
  } = useRouter();

  const categoryPath = Array.isArray(slug) ? slug.join('/') : (slug ?? '');

  return useQuery({
    enabled: enabled && !!categoryPath,
    placeholderData: keepPreviousData,
    queryFn: () => getCategoryInfoUsingPath(categoryPath, locale),
    queryKey: categoryKeys.categoryInfoFromPath(categoryPath, locale),
    refetchOnWindowFocus: false,
  });
};

const useCategory = (
  categoryCode: string,
  categoryLevel: number,
  fieldsParam: ApiParamFields = API_PARAM_FIELDS.FULL,
) => {
  const { locale } = useRouter();
  const isL2 = categoryLevel === 2;
  const fields = isL2 ? API_PARAM_FIELDS.L2 : fieldsParam;

  return useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchCategory({ categoryCode, fields, locale }),
    queryKey: categoryKeys.category({ categoryCode, fields, locale }),
    refetchOnWindowFocus: false,
  });
};

const useCategorySubCategories = (categoryCode: string) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchCategorySubCategories({ categoryCode, locale }),
    queryKey: categoryKeys.categorySubCategories({ categoryCode, locale }),
    refetchOnWindowFocus: false,
  });
};

const useCategorySearch = (category: Category | undefined) => {
  const { isReady, locale, query } = useRouter();
  const { currentPage, pageSize, q, sort } = query || {};
  const params: SearchCategoryParams = {
    categoryCode: category?.code ?? '',
    currentPage: Number(currentPage) || 0,
    fields: 'FULL',
    locale,
    pageSize: Number(pageSize) || NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1,
    query: q || DEFAULT_QUERY,
    sort: sort ?? '',
    withContentTiles: !!category?.plpContentTiles?.length,
  };

  return useQuery({
    enabled: isReady && !!category?.code,
    placeholderData: keepPreviousData,
    queryFn: async () => searchCategory(params),
    queryKey: categoryKeys.search(params),
    refetchOnWindowFocus: false,
  });
};

const useCategorySilo = (categoryCode: string) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchCategorySilo({ categoryCode, locale }),
    queryKey: categoryKeys.silo({ categoryCode, locale }),
    refetchOnWindowFocus: false,
  });
};

const useCategoryInfoLinks = (categoryCode: string) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!categoryCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchCategoryInfoLinks({ categoryCode, locale }),
    queryKey: categoryKeys.infoLinks({ categoryCode, locale }),
    refetchOnWindowFocus: false,
  });
};

const usePromotedBrands = (categoryCode: string) => {
  const { data: webConfig } = useWebConfig();
  const { locale } = useRouter();

  return useQuery({
    enabled: !!categoryCode && !!webConfig?.enablePromotedBrands,
    placeholderData: keepPreviousData,
    queryFn: () => fetchCategoryPromotedBrands({ categoryCode, locale }),
    queryKey: categoryKeys.promotedBrands({ categoryCode, locale }),
    refetchOnWindowFocus: false,
    select: (data) => data?.brands || [],
  });
};

export {
  useCategory,
  useCategoryInfoFromPath,
  useCategoryInfoLinks,
  useCategorySearch,
  useCategorySilo,
  useCategorySubCategories,
  usePromotedBrands,
};
