import { ConsentResult } from 'types/Cact';
import { logErrorResponse } from 'utils/loggerUtil';

const fetchCactConsent = async () => {
  try {
    return new Promise<ConsentResult>((resolve, reject) => {
      if (!window?.cact) reject(Error('Missing cact'));
      window?.cact?.('consent.get', (result) => {
        resolve(result);
      });
    });
  } catch (error) {
    logErrorResponse('Cact Connector', error, 'Error fetching consent');
    throw error;
  }
};

export { fetchCactConsent };
