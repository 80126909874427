import { API_PARAM_FIELDS } from 'constants/api';
import { CURRENT_BRAND, IS_KREFEL } from 'constants/brand';
import QueryString from 'qs';
import { DeliveryMode } from 'types/DeliveryMethods';
import { Product, ProductReference, RealTimeInfo } from 'types/Product';
import { ProductInstalment } from 'types/ProductInstalment';
import { ProductVariant, VariantClassification } from 'types/ProductVariant';
import { ReviewsResult } from 'types/Review';
import { SearchProductsByQuery } from 'types/Search';
import { createSolrQuery } from 'utils/solrUtils';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

export type FetchProductParams = {
  locale: string;
  productCode?: string;
};

const fetchProduct = async ({ locale, productCode }: FetchProductParams) => {
  try {
    if (!productCode) throw new Error('Product code is required');
    const { data } = await apiClient<Product>({
      params: {
        fields: 'PDP',
        lang: locale,
        showEndOfLife: true,
      },
      url: `/products/${productCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching product with code ${productCode}`);
    throw error;
  }
};

export type FetchProductsParams = {
  filterOnStock?: boolean;
  locale: string;
  pageSize: number;
  productCodes?: string[];
  pushCnCToBack?: boolean;
};

const fetchProducts = async ({ filterOnStock, locale, pageSize, productCodes, pushCnCToBack }: FetchProductsParams) => {
  try {
    if (!productCodes?.length) throw new Error('Product codes are required');
    const productCodesQuery = productCodes.join(',');
    const { data } = await apiClient<{ products: Product[] }>({
      params: {
        fields: API_PARAM_FIELDS.FULL,
        productCode: productCodesQuery,
        ...(filterOnStock && { filterOnStock }),
        ...(pushCnCToBack && { pushPUISoBack: pushCnCToBack }),
        ...(pageSize && { pageSize }),
        lang: locale,
      },
      url: `/products/list`,
    });

    return data.products;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching products with codes ${productCodes}`);
    throw error;
  }
};

export type FetchRealtimeInfoParams = {
  locale: string;
  productCodes?: string[];
};

const fetchRealtimeInfo = async ({ locale, productCodes }: FetchRealtimeInfoParams) => {
  try {
    if (!productCodes?.length) throw new Error('Product codes are required');
    const productCodesQuery = QueryString.stringify(
      { productCodes },
      {
        addQueryPrefix: true,
        indices: false,
      },
    );
    const { data } = await apiClient<{ products: RealTimeInfo[] }>({
      params: {
        lang: locale,
      },
      url: `/products/realtimeinformation${productCodesQuery}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching realtime info products with code ${productCodes}`);
    throw error;
  }
};

export type FetchReviewsParams = {
  currentPage?: number;
  locale: string;
  pageSize?: number;
  productCode: string;
  sort?: string;
};

const fetchReviews = async ({ currentPage, locale, pageSize, productCode, sort }: FetchReviewsParams) => {
  try {
    const { data } = await apiClient<ReviewsResult>({
      params: {
        currentPage,
        fields: 'FULL',
        lang: locale,
        pageSize,
        sort,
      },
      url: `/products/${productCode}/reviews`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching reviews for product with code ${productCode}`);
    throw error;
  }
};

export type FetchProductClassificationsParams = {
  locale: string;
  productCode?: string;
};

const fetchClassifications = async ({ locale, productCode }: FetchProductClassificationsParams) => {
  try {
    if (!productCode) throw new Error('Product code is required');
    const { data } = await apiClient({
      params: {
        lang: locale,
      },
      url: `/products/${productCode}/classifications`,
    });

    return data?.classifications;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching classifications for product with code ${productCode}`);
    throw error;
  }
};

type ProductVariantsResponse = {
  classifications?: VariantClassification[];
  code?: string;
  items?: ProductVariant[];
};

export type FetchProductVariantsParams = {
  locale: string;
  productCode?: string;
};

const fetchProductVariants = async ({ locale, productCode }: FetchProductVariantsParams) => {
  try {
    if (!productCode) throw new Error('Product code is required');
    const { data } = await apiClient<ProductVariantsResponse>({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/products/${productCode}/variants`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Product Connector',
      error,
      `Error fetching product variants for product with code ${productCode}`,
    );
    throw error;
  }
};

const fetchUserCanWriteReview = async (productCode?: string) => {
  try {
    if (!productCode) throw new Error('Product code is required');
    const { data } = await apiClient<{ valid: boolean }>({
      url: `/products/${productCode}/reviews/allow`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Product Connector',
      error,
      `Error fetching user can write review for product with code ${productCode}`,
    );
    throw error;
  }
};

const fetchPopularProducts = async (pageSize = 500) => {
  try {
    const { data } = await apiClient<string[]>({
      params: {
        pageSize,
      },
      url: `/products/popular`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, 'Error fetching popular products');
    throw error;
  }
};

export type FetchProductReferencesParams = {
  locale: string;
  productCode?: string;
  referenceType: string;
};

const fetchProductReferences = async ({ locale, productCode, referenceType }: FetchProductReferencesParams) => {
  try {
    if (!productCode) throw new Error('Product code is required');
    const { data } = await apiClient<{ references: ProductReference[] }>({
      params: {
        fields: 'FULL',
        lang: locale,
        pageSize: 20,
        referenceType,
      },
      url: `/products/${productCode}/references`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Product Connector',
      error,
      `Error fetching ${referenceType} references for product with code ${productCode}`,
    );
    throw error;
  }
};

const fetchMonthlyInstalment = async (price: number, months: number) => {
  try {
    const { data } = await apiClient<ProductInstalment>({
      params: {
        amount: price,
        fields: 'FULL',
        numberOfMonths: months,
      },
      url: `/santander/instalment`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Product Connector',
      error,
      `Error fetching product instalment with price ${price} and number of months ${months}}`,
    );
    throw error;
  }
};

export type FetchRecentlyViewedProductsParams = {
  locale: string;
  productCodes?: string[];
};

const fetchRecentlyViewedProducts = async ({ locale, productCodes = [] }: FetchRecentlyViewedProductsParams) => {
  const queryParams = productCodes
    .filter((productCode) => productCode)
    .map((productCode) => `productCode=${productCode}`);

  try {
    const { data } = await apiClient<{ products: Product[] }>({
      params: {
        fields: 'RECENTLY_VIEWED',
        lang: locale,
      },
      url: `/products/list?${queryParams.join('&')}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, 'Error fetching recently viewed products');
    throw error;
  }
};

export type FetchProductsByQueryParams = {
  currentPage?: number;
  filterOnStock?: boolean;
  locale: string;
  pageSize?: number;
  pushCnCToBack?: boolean;
  query: string;
};

const fetchProductsByQuery = async ({
  currentPage,
  filterOnStock,
  locale,
  pageSize,
  pushCnCToBack,
  query,
}: FetchProductsByQueryParams) => {
  try {
    if (!query) throw new Error('Query is required');
    const queryWithFilters = createSolrQuery(query, filterOnStock, pushCnCToBack);
    const { data } = await apiClient<SearchProductsByQuery>({
      params: {
        query: queryWithFilters,
        ...(currentPage && { currentPage }),
        ...(pageSize && { productSize: pageSize }),
        fields: 'FULL',
        lang: locale,
      },
      url: `/products/query`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching products with query ${query}`);
    throw error;
  }
};

export type FetchProductDeliveryModesParams = {
  country?: string;
  locale: string;
  location?: string;
  postalCode?: string;
  productCode: string;
};

const fetchProductDeliveryModes = async ({
  country,
  locale,
  location,
  postalCode,
  productCode,
}: FetchProductDeliveryModesParams) => {
  try {
    const { data } = await apiClient<{ deliveryModes: DeliveryMode[] }>({
      params: {
        country,
        fields: 'FULL',
        lang: locale,
        location,
        postalCode,
      },
      url: `/products/${productCode}/deliverymodes${IS_KREFEL ? '' : `/${CURRENT_BRAND}`}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Product Connector', error, `Error fetching delivery modes for products with code ${productCode}`);
    throw error;
  }
};

export {
  fetchClassifications,
  fetchMonthlyInstalment,
  fetchPopularProducts,
  fetchProduct,
  fetchProductDeliveryModes,
  fetchProductReferences,
  fetchProducts,
  fetchProductsByQuery,
  fetchProductVariants,
  fetchRealtimeInfo,
  fetchRecentlyViewedProducts,
  fetchReviews,
  fetchUserCanWriteReview,
};
