import { apiClient } from 'helpers/ApiClient';
import config from '../../config';
import { CONTACT_FORM_PARTNER } from '../../constants/contactForms';
import SalesForceHelper from '../../helpers/SalesForceHelper';
import {
  createSalesForceProductArray,
  createSalesForceProductObject,
} from '../../utils/analyticsProductDataUtil';
import {
  registerUserEmail,
} from '../../utils/salesforceUtil';

const { platform } = config;

/**
 * The Redux salesforce module containing the salesforce related action, action types and reducer.
 */

/**
 * Action types
 */
export const SUBSCRIBE_SALESFORCE_NEWSLETTER = `${platform}/salesforce/SUBSCRIBE_SALESFORCE_NEWSLETTER`;
export const SUBSCRIBE_SALESFORCE_NEWSLETTER_SUCCESS = `${platform}/salesforce/SUBSCRIBE_SALESFORCE_NEWSLETTER_SUCCESS`;
export const SUBSCRIBE_SALESFORCE_NEWSLETTER_FAIL = `${platform}/salesforce/SUBSCRIBE_SALESFORCE_NEWSLETTER_FAIL`;
export const GET_SALESFORCE_FORM_TYPES = `${platform}/salesforce/GET_SALESFORCE_FORM_TYPES`;
export const GET_SALESFORCE_FORM_TYPES_SUCCESS = `${platform}/salesforce/GET_SALESFORCE_FORM_TYPES_SUCCESS`;
export const GET_SALESFORCE_FORM_TYPES_FAIL = `${platform}/salesforce/GET_SALESFORCE_FORM_TYPES_FAIL`;
export const SUBMIT_SALESFORCE_FORM = `${platform}/salesforce/SUBMIT_SALESFORCE_FORM`;
export const SUBMIT_SALESFORCE_FORM_SUCCESS = `${platform}/salesforce/SUBMIT_SALESFORCE_FORM_SUCCESS`;
export const SUBMIT_SALESFORCE_FORM_FAIL = `${platform}/salesforce/SUBMIT_SALESFORCE_FORM_FAIL`;
export const UPDATED_SALES_FORCE_PRODUCTS = `${platform}/salesforce/UPDATED_SALES_FORCE_PRODUCTS`;
export const UPDATED_SALES_FORCE_PRODUCTS_SUCCESS = `${platform}/salesforce/UPDATED_SALES_FORCE_PRODUCTS_SUCCESS`;
export const UPDATED_SALES_FORCE_PRODUCTS_FAIL = `${platform}/salesforce/UPDATED_SALES_FORCE_PRODUCTS_FAIL`;
/**
 * Actions
 */

/**
 * Function which subscribes a customer to the newsletter
 */
export const subscribeNewsletter = (email) => {
  registerUserEmail(email);
  return {
    promise: () =>
      apiClient(
        { method: "POST", url: `/salesforce/newsletter/subscribe/${email}` }
      ),
    types: [
      SUBSCRIBE_SALESFORCE_NEWSLETTER,
      SUBSCRIBE_SALESFORCE_NEWSLETTER_SUCCESS,
      SUBSCRIBE_SALESFORCE_NEWSLETTER_FAIL,
    ],
  };
};

/**
 * Function which retrieves the salesforce forms
 * @param {string} locale - the current locale
 * @returns {{types: [*,*,*], promise: (function(*))}} the get salesforce form types action
 */
export const getSalesforceFormTypes = (locale) => ({
  promise: () =>
    apiClient({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/salesforce/forms`,
    }),
  types: [
    GET_SALESFORCE_FORM_TYPES,
    GET_SALESFORCE_FORM_TYPES_SUCCESS,
    GET_SALESFORCE_FORM_TYPES_FAIL,
  ],
});

/**
 * Function which submits the salesforce form data
 * @param {object} contactInfo - the salesforce contact form data
 * @param {object} contactFormPartner - the contact form partner
 * @return {{types: [*,*,*], promise: (function(*): (Request|*))}} the submit salesforce form action
 */
export const submitSalesForceForm = (contactInfo, contactFormPartner = undefined) => {
  registerUserEmail(contactInfo.emailAddressCustomer);
  let contactEndpoint = `/salesforce/contact/submit`;

  if (
    contactFormPartner &&
    contactFormPartner === CONTACT_FORM_PARTNER.SERVICE_CLOUD
  ) {
    contactEndpoint = `/salesforce/contact/submit/servicecloud`;
  }
  return {
    promise: () =>
      apiClient({
        data: contactInfo,
        method: "POST",
        params: {
          fields: 'FULL',
        },
        url: contactEndpoint,
      }),
    types: [
      SUBMIT_SALESFORCE_FORM,
      SUBMIT_SALESFORCE_FORM_SUCCESS,
      SUBMIT_SALESFORCE_FORM_FAIL,
    ],
  };
};

/**
 * Function which sends a product stream to the salesForce api
 * @param {Object|Array} productStream - the product(s) stream
 * @param {String} locale - the current locale
 * @return {{types: [*,*,*], promise: (function(*=): Promise)}}
 * the update salesforce products action
 */
export const updateSalesForceProducts = (productStream, locale) => {
  let salesForceProductStream;

  if (productStream && Array.isArray(productStream)) {
    salesForceProductStream = createSalesForceProductArray(
      productStream,
      locale
    );
  } else if (productStream && typeof productStream === 'object') {
    salesForceProductStream = createSalesForceProductObject(
      productStream,
      locale
    );
  }

  if (salesForceProductStream) {
    return {
      promise: () =>
        new SalesForceHelper().sendProductItemsStream(
          salesForceProductStream
        ),
      types: [
        UPDATED_SALES_FORCE_PRODUCTS,
        UPDATED_SALES_FORCE_PRODUCTS_SUCCESS,
        UPDATED_SALES_FORCE_PRODUCTS_FAIL,
      ],
    };
  }

  return {
    promise: () => Promise.resolve(),
    types: [
      UPDATED_SALES_FORCE_PRODUCTS,
      UPDATED_SALES_FORCE_PRODUCTS_SUCCESS,
      UPDATED_SALES_FORCE_PRODUCTS_FAIL,
    ],
  };
};

/**
 * Reducer
 */
const initialState = {
  isSubscribing: false,
  salesforceForms: null,
};

/**
 * The salesforce reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUBSCRIBE_SALESFORCE_NEWSLETTER: {
      return {
        ...state,
        isSubscribing: true,
      };
    }
    case SUBSCRIBE_SALESFORCE_NEWSLETTER_FAIL:
    case SUBSCRIBE_SALESFORCE_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        isSubscribing: false,
      };
    }
    case GET_SALESFORCE_FORM_TYPES_SUCCESS: {
      return {
        ...state,
        salesforceForms: action?.result?.data?.salesforceForms,
      };
    }

    default:
      return state;
  }
}
