export const CONFIGURATION_TYPES = {
  CUSTOM: 'custom',
};

export const SORT_TYPES = {
  NAME_ASC: 'PC_NAME_ASC',
  NAME_DESC: 'PC_NAME_DESC',
  PRICE_ASC: 'PC_PRICE_ASC',
  PRICE_DESC: 'PC_PRICE_DESC',
  RELEVANCE: 'PC_RELEVANCE',
};

export const FORM_FIELDS = { EXTRA_WISHES: 'extraWishes' } as const;
