import {
  FetchCategoryInfoLinksParams,
  FetchCategoryParams,
  FetchCategoryPromotedBrandsParams,
  FetchCategorySiloParams,
  FetchCategorySubCategoriesParams,
  SearchCategoryParams,
} from './connector';

export const categoryKeys = {
  category: (params: FetchCategoryParams) => ['category', params],
  categoryInfoFromPath: (categoryPath: string, locale: string) => ['categoryInfoFromPath', categoryPath, locale],
  categoryLevel: (categoryCode: string) => ['categoryLevel', categoryCode],
  categorySubCategories: (params: FetchCategorySubCategoriesParams) => ['categorySubCategories', params],
  infoLinks: (params: FetchCategoryInfoLinksParams) => ['categoryInfoLinks', params],
  promotedBrands: (params: FetchCategoryPromotedBrandsParams) => ['promotedBrands', params],
  search: (params: SearchCategoryParams) => ['search', params],
  silo: (params: FetchCategorySiloParams) => ['categorySilo', params],
};
