/* eslint-disable no-labels */
// TODO => Check if file can be cleaned up

import { BRAND_SPECIFIC_DEFAULT_LOCALE } from 'constants/i18n';
import { QueryParams } from 'types/Url';
import config from '../config';
import { localizedPathnames, pathnames } from '../i18n/pathnames';
import localizedRoutes from '../i18n/routes';

/**
 * Normalizes the paths.
 *
 * @param {string} path - The the path to normalize.
 * @param {boolean} removeLanguage - Flag if the language prefix should be removed.
 * @param {boolean=} removeHost - The remove host from path flag.
 * @param {string=} locale - the current locale
 * @returns {string} The normalized path.
 */
export function normalizePath(path = '', removeLanguage?: boolean, removeHost?: boolean, locale?: string) {
  let normalizedPath = path.trim();

  if (!removeHost && normalizedPath.startsWith('/')) {
    normalizedPath = normalizedPath.substr(1);
  } else if (removeHost && !normalizedPath.startsWith('/') && locale) {
    const splitPath = normalizedPath.split(`/${locale}`).length > 1 ? normalizedPath.split(`/${locale}`)[1] : null;
    if (splitPath) {
      normalizedPath = splitPath;
    }
  }

  if (removeLanguage) {
    const splitPath = normalizedPath.split('/');
    const pathLanguage = splitPath[0];
    if (config.i18n.locales.indexOf(pathLanguage) >= 0) {
      splitPath.shift();

      normalizedPath = splitPath.join('/');
    }
  }

  return normalizedPath;
}

/**
 * Replaces the path parameters with their provided values.
 *
 * @param {string} rawPath - The raw path the the parameter placeholders.
 * @param {object} params - Object containing the path parameter values.
 * The object keys should match the path placeholders.
 * @returns {string} Path with replaced path parameters.
 */
export function replacePathParameters(rawPath: string, params: QueryParams) {
  const rawPathArray = rawPath.split('/');
  const formattedPath = [...rawPathArray];

  rawPathArray.forEach((segment, index: number) => {
    if (segment.startsWith(':')) {
      const segmentValue = params[segment.substring(1)] + '';

      if (segmentValue) {
        formattedPath[index] = segmentValue;
      }
    }
  });

  return formattedPath.join('/');
}

/**
 * Extracts the query parameters from the path.
 *
 * @param {string} path - The path that the query parameters need to be extracted from.
 * @returns {object|undefined} Object containing the query parameters.
 */
function extractQueryParameters(path: string) {
  const query: QueryParams = {};
  const splitPath = path.split('?');

  if (splitPath[1]) {
    const splitQuery = splitPath[1].split('&');

    if (splitQuery.length > 0) {
      splitQuery.forEach((querySet) => {
        const querySetSplit = querySet.split('=');
        const querySetSplitFirst = querySetSplit[0];
        const querySetSplitSecond = querySetSplit[1];

        if (querySetSplit.length === 2) {
          query[querySetSplitFirst] = querySetSplitSecond;
        }
      });
    }
  }

  return query;
}

/**
 * Retrieves the language from the url path.
 *
 * @param {string} path - The url path.
 * @returns {string|undefined} The found locale.
 */
export function getLanguageFromPath(path: string) {
  const splitPath = normalizePath(path).split('/');

  if (config.i18n.locales.indexOf(splitPath[0]) >= 0) {
    return splitPath[0];
  }

  return undefined;
}

/**
 * Retrieves the route info for a localized path.
 *
 * @param {string} path - The path to retrieve the route info from.
 * @returns {{name: *, query: *, params: *}} Found route info.
 */
export function getRouteInfoFromPath(path: string) {
  const pathLanguage = getLanguageFromPath(path);
  const pathName = normalizePath(path.split('?')[0], true);
  const splitPathName = pathName.split('/');
  const query = extractQueryParameters(path);

  // loop over all available route configs
  const routeConfigKeys = Object.keys(localizedRoutes);

  // eslint-disable-next-line no-restricted-syntax
  outer: for (let routeConfigIndex = 0; routeConfigIndex <= routeConfigKeys.length; routeConfigIndex += 1) {
    const routeName = routeConfigKeys[routeConfigIndex];

    if (routeName) {
      const routeConfig = localizedRoutes[routeName];
      const configPath = pathLanguage ? normalizePath(routeConfig[pathLanguage]) : undefined;

      if (configPath !== undefined && configPath !== null) {
        const splitConfigPath = configPath.split('/');

        if (splitConfigPath.length === splitPathName.length) {
          const params: QueryParams = {};

          // loop over each route part and check if match
          for (let pathPartIndex = 0; pathPartIndex < splitConfigPath.length; pathPartIndex += 1) {
            const configPathPart = splitConfigPath[pathPartIndex];
            const pathNamePart = splitPathName[pathPartIndex];

            // if part is q parameter
            if (configPathPart.startsWith(':')) {
              params[configPathPart.split(':')[1]] = pathNamePart;
              // eslint-disable-next-line no-continue
              continue;
            }

            // if not a match --> try next route config path
            if (configPathPart !== pathNamePart) {
              // eslint-disable-next-line no-labels, no-continue
              continue outer;
            }
          }
          return {
            name: routeName,
            params: Object.keys(params).length > 0 ? params : undefined,
            query: Object.keys(query).length > 0 ? query : undefined,
          };
        }
      }
    }
  }

  return {
    name: 'index',
  };
}

export const getLocalizedRoute = (pathnameProp: string, localeProp: string, options: { query?: QueryParams } = {}) => {
  const pathname = pathnameProp || pathnames.INDEX;
  const locale = localeProp || BRAND_SPECIFIC_DEFAULT_LOCALE;

  const selectedRoute = localizedPathnames[pathname];

  if (!selectedRoute) return;

  const routerObject = {
    pathname: selectedRoute[locale],
    query: options?.query,
  };

  return routerObject;
};
