import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { fetchCactConsent } from './connector';
import { cactKeys } from './index';

const useCactConsent = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    window?.cact?.('consent.onUpdate', () => {
      queryClient.invalidateQueries({ queryKey: cactKeys.consent() });
    });
  }, []);
  return useQuery({ queryFn: () => fetchCactConsent(), queryKey: cactKeys.consent() });
};

export { useCactConsent };
