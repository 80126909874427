import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AddToCartState {
  contractKey?: string | null;
}

export interface AddToCartStore extends AddToCartState {
  actions: {
    reset: () => void;
    setEntryContractKey: (contractKey: string | null | undefined) => void;
  };
}

const initialState: AddToCartState = {
  contractKey: null,
};

export const useAddToCartStore = create<AddToCartStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        reset: () => set(() => ({ contractKey: null })),
        setEntryContractKey: (contractKey) => set(() => ({ contractKey })),
      },
    }),
    { name: 'AddToCartStore', trace: true },
  ),
);
