import { SalesforceProduct } from 'types/Salesforce';
import config from '../config';

export const registerUserEmail = (email: string) => {
  if (window._etmc && email) {
    if (config.salesForce.orgId) window._etmc.push(['setOrgId', config.salesForce.orgId]);
    window._etmc.push(['setUserInfo', { email }]);
    window._etmc.push(['trackPageView']);
  }
};

export const getProductIds = (products: SalesforceProduct[]) => products?.map((product) => product.id) || [];
