export const FORM_TYPES = {
  AFTER_PURCHASE: 'NA_VERKOOP',
  CANCEL: 'ANNULEER_BESTELLING',
  CHANGE: 'WIJZIG_BESTELLING',
  DELIVERY: 'LEVERING',
  EXCHANGE: 'OMRUILEN',
  OTHER_QUESTION: 'ANDERE_VRAAG',
  PAYMENT: 'BETALINGEN',
  SUCCESS: 'SUCCESS',
};

export const CONTACT_FORM_PARTNER = {
  MARKETING: 'SALESFORCEMARKETING',
  SERVICE_CLOUD: 'SALESFORCESERVICECLOUD',
};
