import useRouter from 'hooks/useRouter';
import { GetServerSidePropsContext } from 'next';

// For getting a single router param, for multiple just destructure the router.query object
export const useRouterParam = (paramName: string) => {
  const router = useRouter();
  const param = router.query[paramName];
  return Array.isArray(param) ? param[0] : param;
};

export const getServerSideQueryParam = (context: GetServerSidePropsContext, paramName: string) => {
  const param = context.query[paramName];
  return Array.isArray(param) ? param[0] : param;
};
