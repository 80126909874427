import config from '../config';
import { log } from '../utils/loggerUtil';
import { apiClient } from './ApiClient';

/**
 * Helper for sending requests to the salesForce server.
 */

class SalesForceHelper {

  /**
   * Function which sends a products update call to the salesforce api
   * @param {Array|object} payload - the salesforce stream payload
   * @returns {Promise} the send product items stream promise
   */
  sendProductItemsStream(payload) {
    log(
      'SALES_FORCE',
      `The sending product update stream to the salesforce api`
    );

    return new Promise((resolve, reject) => {
      apiClient({
        data: {
          api_key: config.salesForce.apiKey,
          payload,
        },
        isExternalUrl: true,
        method: "POST",
        url: config.salesForce.updateSteam
      })
        .then((result) => {
          log('SALES_FORCE', `The requested products are updated`);
          resolve(result);
        })
        .catch((err) => {
          log('SALES_FORCE', `The requested products could not be updated`);

          reject(err);
        });
    });
  }
}

export default SalesForceHelper;
