import { AnalyticsPageType, PianoPageName, PianoPageType } from 'constants/analytics';
import ABTastyTrackingHelper from 'helpers/ABTastyTrackingHelper';
import PianoTrackingHelper from 'helpers/PianoTrackingHelper';
import { Hit } from 'types/Algolia';
import { Cart } from 'types/Cart';
import { Category } from 'types/Category';
import { Order } from 'types/Order';
import { Product } from 'types/Product';
import { Store } from 'types/Store';
import { User } from 'types/User';

interface PageViewParams {
  analyticsPageName?: AnalyticsPageType | PianoPageName;
  analyticsPageType?: AnalyticsPageType | PianoPageType;
  category?: Category;
  locale: string;
  path: string;
  pathname: string;
  product?: Product;
  slug?: string;
  store?: Store;
  user?: User;
}

interface PDPViewParams {
  product: Product;
}

interface PLPViewParams {
  category?: Category;
  hits?: Hit[];
  pageNumber?: number;
  products?: Product[];
}

interface SearchResultViewParams {
  amountOfResults: number;
  keyword: string;
  pageNumber: number;
}

interface CartViewParams {
  cart: Cart;
}

interface CartDeliveryViewParams {
  cart: Cart;
}

interface CartPaymentViewParams {
  cart: Cart;
}

interface OrderConfirmationParams {
  order: Order;
}

interface AddToCartParams {
  cart?: Cart;
  hit?: Hit;
  product?: Product;
}

interface CartCreationParams {
  cart: Cart;
}

interface RemoveFromCartParams {
  cart: Cart;
  product: Product;
}

export interface TrackingEvents {
  onAddToCart: (payload: AddToCartParams) => void;
  onCartCreation: (payload: CartCreationParams) => void;
  onCartDeliveryView: (payload: CartDeliveryViewParams) => void;
  onCartPaymentView: (payload: CartPaymentViewParams) => void;
  onCartView: (payload: CartViewParams) => void;
  onOrderConfirmationView: (payload: OrderConfirmationParams) => void;
  onPDPView: (payload: PDPViewParams) => void;
  onPLPView: (payload: PLPViewParams) => void;
  onPageView: (payload: PageViewParams) => void;
  onRemoveFromCart: (payload: RemoveFromCartParams) => void;
  onSearchResultView: (payload: SearchResultViewParams) => void;
}

const useTrackingEvents = () => {
  const listeners: Partial<TrackingEvents>[] = [PianoTrackingHelper, ABTastyTrackingHelper];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = (event: keyof TrackingEvents, payload?: any) => {
    listeners.forEach((listener) => listener[event]?.(payload));
  };

  const onPageView = (payload: PageViewParams) => {
    dispatch('onPageView', payload);
  };

  const onPDPView = (payload: PDPViewParams) => {
    dispatch('onPDPView', payload);
  };

  const onPLPView = (payload: PLPViewParams) => {
    dispatch('onPLPView', payload);
  };

  const onSearchResultView = (payload: SearchResultViewParams) => {
    dispatch('onSearchResultView', payload);
  };

  const onCartView = (payload: CartViewParams) => {
    dispatch('onCartView', payload);
  };

  const onCartDeliveryView = (payload: CartDeliveryViewParams) => {
    dispatch('onCartDeliveryView', payload);
  };

  const onCartPaymentView = (payload: CartPaymentViewParams) => {
    dispatch('onCartPaymentView', payload);
  };

  const onOrderConfirmationView = (payload: OrderConfirmationParams) => {
    dispatch('onOrderConfirmationView', payload);
  };

  const onAddToCart = (payload: AddToCartParams) => {
    dispatch('onAddToCart', payload);
  };

  const onCartCreation = (payload: CartCreationParams) => {
    dispatch('onCartCreation', payload);
  };

  const onRemoveFromCart = (payload: RemoveFromCartParams) => {
    dispatch('onRemoveFromCart', payload);
  };

  return {
    onAddToCart,
    onCartCreation,
    onCartDeliveryView,
    onCartPaymentView,
    onCartView,
    onOrderConfirmationView,
    onPDPView,
    onPLPView,
    onPageView,
    onRemoveFromCart,
    onSearchResultView,
  } as TrackingEvents;
};

export default useTrackingEvents;
