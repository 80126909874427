import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import Script from 'next/script';
import { useEffect } from 'react';
import { CartEntry } from 'types/Cart';
import config from '../../../config';
import { SALESFORCE_SCRIPT_ID } from '../../../constants/salesforce';
import { useProduct } from '../../../features';
import { useCategoryInfoFromPath } from '../../../features/category/queries';
import { pathnames } from '../../../i18n/pathnames';

const SalesForceScript = () => {
  const { pathname } = useRouter();

  const { data: productData } = useProduct({ enabled: pathname === pathnames.PRODUCT });
  const { data: categoryCodeData } = useCategoryInfoFromPath(pathname === pathnames.CATEGORY);
  const { authResolved, user } = useAuthResolver();

  const cartEntries = useSelector((state) => state.cart?.cart?.entries);
  const orderEntries = useSelector((state) => state.checkout?.order?.entries);

  const getEntryItems = (entries?: CartEntry[]) => {
    const items = entries?.map((entry) => {
      const cartEntryItem = {
        item: entry?.product?.code,
        price: entry?.basePrice?.value?.toString(),
        quantity: entry?.quantity?.toString(),
        unique_id: entry?.product?.code,
      };

      // Products with discount
      if (entry?.totalProductStrikePrice?.value) {
        return {
          ...cartEntryItem,
          price: entry?.totalProductStrikePrice?.value?.toString(),
          sale_price: entry?.basePrice?.value?.toString(),
        };
      }

      return cartEntryItem;
    });

    return items;
  };

  useEffect(() => {
    const items = getEntryItems(cartEntries);
    window?._etmc?.push(['trackCart', { cart: items }]);
  }, [cartEntries]);

  useEffect(() => {
    if (orderEntries?.length) {
      const items = getEntryItems(orderEntries);
      window?._etmc?.push(['trackConversion', { cart: items }]);
    }
  }, [orderEntries]);

  useEffect(() => {
    if (pathname === pathnames.CATEGORY && !!categoryCodeData?.code) {
      window?._etmc?.push(['trackPageView', { category: categoryCodeData.code }]);
    }

    if (pathname === pathnames.PRODUCT && !!productData?.code) {
      window?._etmc?.push(['trackPageView', { item: productData.code }]);
    }

    window?._etmc?.push(['trackPageView']);
  }, [pathname]);

  useEffect(() => {
    if (authResolved) {
      window.__data = {
        ...window.__data,
        auth: {
          ...window.__data?.auth,
          user,
        },
      };
    }
  }, [authResolved, user]);

  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `var _etmc = []; _etmc.push(["setOrgId", "${config.salesForce.orgId}"]);`,
      }}
      id={SALESFORCE_SCRIPT_ID}
      type="text/javascript"
    />
  );
};

export default SalesForceScript;
