import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppComponent from 'components/App/AppComponent';
import 'config/tailwind/styles.css';
import { BRAND_SPECIFIC_DEFAULT_LOCALE } from 'constants/i18n';
import { DEFAULT_STALE_TIME } from 'constants/reactQuery';
import { i18nKeys } from 'features';
import { NextComponentType } from 'next';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { type AppContext, type AppInitialProps, type AppLayoutProps } from 'next/app';
import NProgress from 'nprogress';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetLocalization } from 'redux/modules/localization';
import wrapper from 'redux/store';
import { useUIActions } from 'store/ui';
import type { TranslationKeys } from 'types/I18n';
import { pushVirtualPageView } from 'utils/analyticsUtil';
import 'utils/fontAwesome';

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

interface PageProps {
  dehydratedState?: object;
  session?: Session;
  translationKeys?: TranslationKeys;
}

type RouteChangeHandler = (url: string, options: { shallow?: boolean }) => void;

const MyApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  ...props
}: AppLayoutProps<PageProps>) => {
  const { pageProps, router } = props;
  const dispatch = useDispatch();

  const [showDevtools, setShowDevtools] = useState(false);

  const { setLoading } = useUIActions();

  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            // Define the amount of time it takes in order for data to become stale
            staleTime: DEFAULT_STALE_TIME,
          },
        },
      }),
  );

  // Do not place this block of code into AppComponent, this will introduce a breaking bug
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    const routeChangeStart: RouteChangeHandler = (nextUrl, { shallow }) => {
      if (!shallow) {
        dispatch(resetLocalization()); // Reset stored localizedUrls on non shallow route change to prevent possible redirects on translation
        queryClient.removeQueries({ exact: true, queryKey: i18nKeys.localizedUrls() });

        setLoading(true);
        pushVirtualPageView({
          pageUrl: nextUrl,
          previousPageAsPath: router.asPath,
          previousPageLocale: router.locale ?? BRAND_SPECIFIC_DEFAULT_LOCALE,
        });

        NProgress.start();
      }
    };
    const routeChangeComplete: RouteChangeHandler = (_, { shallow }) => {
      if (!shallow) {
        NProgress.done();
        setLoading(false);
      }
    };

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);
    router.events.on('routeChangeError', routeChangeComplete);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
      router.events.off('routeChangeError', routeChangeComplete);
    };
  }, []);

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <SessionProvider session={pageProps?.session}>
      <QueryClientProvider client={queryClient}>
        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
        <ReactQueryDevtools initialIsOpen={false} />
        <HydrationBoundary state={pageProps?.dehydratedState}>
          <AppComponent>{getLayout(<Component {...props.pageProps} />)}</AppComponent>
        </HydrationBoundary>
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default wrapper.withRedux(MyApp);
