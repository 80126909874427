import useRouter from 'hooks/useRouter';
import Script from 'next/script';
import { detectEmbedded } from 'utils/windowUtil';

const localeMap: Record<string, string | undefined> = {
  de: process.env.NEXT_PUBLIC_COMMANDERS_ACT_DE_URL,
  en: process.env.NEXT_PUBLIC_COMMANDERS_ACT_EN_URL,
  fr: process.env.NEXT_PUBLIC_COMMANDERS_ACT_FR_URL,
  nl: process.env.NEXT_PUBLIC_COMMANDERS_ACT_NL_URL,
};

const CommandersActScript = () => {
  const { locale } = useRouter();
  const isWebview = detectEmbedded();

  const url = localeMap[locale];

  if (!url || isWebview) {
    return null;
  }

  return (
    <>
      <Script
        id="cact-init-script"
        dangerouslySetInnerHTML={{
          __html: `window.caReady = window.caReady || []; window.cact = function() { window.caReady.push(arguments); };`,
        }}
      />
      <Script src={url} type="text/javascript" />
    </>
  );
};

export default CommandersActScript;
