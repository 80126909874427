const { config, library } = require('@fortawesome/fontawesome-svg-core');

import {
  faAngleDown as faAngleDownLight,
  faAngleRight as faAngleRightLight,
  faClock as faClockLight,
  faDownload as faDownloadLight,
  faEllipsis as faEllipsisLight,
  faEquals as faEqualsLight,
  faHeart as faHeartLight,
  faLocationDot as faLocationDotLight,
  faNotEqual as faNotEqualLight,
  faPhoneRotary as faPhoneRotaryLight,
  faPrint as faPrintLight,
  faSearch as faSearchLight,
  faSlidersSimple as faSliderSimpleLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleLeft as faAngleLeftRegular,
  faAngleRight as faAngleRightRegular,
  faBars as faBarsRegular,
  faCartPlus as faCartPlusRegular,
  faCartShopping as faCartShoppingRegular,
  faCheckCircle as faCheckCircleRegular,
  faCheck as faCheckRegular,
  faEraser as faEraserRegular,
  faHeart as faHeartRegular,
  faInfoCircle as faInfoCircleRegular,
  faPipe as faPipeRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faTimes as faTimesRegular,
  faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle as faCheckCircleSolid,
  faCheck as faCheckSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faCircleXmark as faCircleXmarkSolid,
  faHeart as faHeartSolid,
  faPlus as faPlusSolid,
  faStar as faStarSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { faPipe as faPipeThin } from '@fortawesome/pro-thin-svg-icons';

library.add(
  // Solids
  faStarSolid,
  faCircleXmarkSolid,
  faCheckCircleSolid,
  faHeartSolid,
  faChevronDownSolid,
  faChevronUpSolid,
  faCheckSolid,
  faPlusSolid,

  // Regulars
  faCartShoppingRegular,
  faHeartRegular,
  faTimesRegular,
  faCheckRegular,
  faCartPlusRegular,
  faCheckCircleRegular,
  faAngleRightRegular,
  faXmarkRegular,
  faInfoCircleRegular,
  faEraserRegular,
  faAngleLeftRegular,
  faBarsRegular,
  faSpinnerThirdRegular,
  faPipeRegular,

  // Lights
  faHeartLight,
  faSliderSimpleLight,
  faDownloadLight,
  faEqualsLight,
  faNotEqualLight,
  faAngleRightLight,
  faPrintLight,
  faPhoneRotaryLight,
  faClockLight,
  faLocationDotLight,
  faEllipsisLight,
  faSearchLight,
  faAngleDownLight,

  // Thins
  faPipeThin,
);

type Solid = {
  name: 'star' | 'circle-xmark' | 'check-circle' | 'heart' | 'chevron-down' | 'chevron-up' | 'check' | 'plus';
  styling: 'fas';
};

type Regular = {
  name:
    | 'angle-right'
    | 'angle-left'
    | 'cart-plus'
    | 'cart-shopping'
    | 'check-circle'
    | 'check'
    | 'eraser'
    | 'heart'
    | 'info-circle'
    | 'times'
    | 'xmark'
    | 'bars'
    | 'spinner-third'
    | 'pipe';
  styling: 'far';
};

type Light = {
  name:
    | 'angle-right'
    | 'heart'
    | 'sliders-simple'
    | 'download'
    | 'print'
    | 'heart'
    | 'sliders-simple'
    | 'download'
    | 'equals'
    | 'not-equal'
    | 'phone-rotary'
    | 'clock'
    | 'location-dot'
    | 'angle-right'
    | 'print'
    | 'search'
    | 'ellipsis'
    | 'angle-down';
  styling: 'fal';
};

type Thin = {
  name: 'pipe';
  styling: 'fat';
};

export type Custom = {
  name: 'user' | 'phone-rotary' | 'info-circle' | 'clock' | 'map-marker-alt';
  styling: 'custom';
};

export type IconLookup = Solid | Regular | Light | Thin | Custom;

config.autoAddCss = false;
config.showMissingIcons = true;
