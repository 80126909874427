import { TrackingEvents } from 'hooks/useTrackingEvents';
import { ABTastyTransactionData, ABTastyTransactionItem } from 'types/ABTasty';
import { OrderEntry } from 'types/Order';
import { log } from 'utils/loggerUtil';
import { nonNullable } from 'utils/typeUtil';

const TRANSACTION_NAME = 'Purchase';

const mapOrderEntryToABTastyTransactionItem = (
  entry: OrderEntry,
  orderCode: string,
): ABTastyTransactionItem | undefined => {
  if (!entry.product) return;

  return {
    ic: entry.product.code,
    in: entry.product.name ?? '',
    ip: entry.totalProductPrice?.value,
    iq: entry.quantity,
    iv: entry.product.brand?.name,
    tid: orderCode,
  };
};

const ABTastryTrackingHelper: Partial<TrackingEvents> = {
  onOrderConfirmationView: ({ order }) => {
    const transactionItems: ABTastyTransactionItem[] =
      order.entries?.map((entry) => mapOrderEntryToABTastyTransactionItem(entry, order.code)).filter(nonNullable) ?? [];
    const transactionData: ABTastyTransactionData = {
      icn: order.entries?.length ?? 0,
      pm: order.paymentMode?.code,
      sm: order.deliveryMode?.code,
      ta: TRANSACTION_NAME,
      tc: order.totalPrice?.currencyIso,
      tcc: order.appliedVouchers?.map((voucher) => voucher.code).join(', ') || undefined,
      tid: order.code,
      tr: order.totalPrice?.value ?? 0,
      ts: order.deliveryCost?.value,
      tt: order.totalTax?.value,
    };
    log('ABTasty tracking', 'onOrderConfirmationView - transaction', transactionData);
    window.abtasty?.send('transaction', transactionData);
    transactionItems.forEach((item) => {
      log('ABTasty tracking', 'onOrderConfirmationView - item', item);
      window.abtasty?.send('item', item);
    });
  },
};

export default ABTastryTrackingHelper;
