import {
  FetchConfigurationParams,
  FetchConfigurationsParams,
  FetchCustomConfigurationParams,
  FetchPartProductsParams,
  FetchUserFavoriteConfigurationsParams,
} from './connector';

export * from './queries';

export const pcConfiguratorKeys = {
  configuration: (params: FetchConfigurationParams) => ['configuration', params],
  configurations: (params: FetchConfigurationsParams) => ['configurations', params],
  customConfiguration: (params: FetchCustomConfigurationParams) => ['customConfiguration', params],
  partProducts: (params: FetchPartProductsParams) => ['partProducts', params],
  userFavoriteConfigurations: (params: FetchUserFavoriteConfigurationsParams) => ['userFavoriteConfigurations', params],
};
