import { Country } from 'types/Address';
import { COUNTRY_CODES, CountryCode } from '../constants/store';

export const formatPostalCode = (cityName?: string, postalCode?: string | number, countryCode?: CountryCode) => {
  if (!cityName || !postalCode) {
    return '';
  }

  if (countryCode === COUNTRY_CODES.LU) {
    return `L-${postalCode} - ${cityName}`;
  }

  return `${postalCode} - ${cityName}`;
};

export const destructurePostalCode = (formattedLocation?: string, country?: Country) => {
  if (!formattedLocation) {
    return undefined;
  }

  const normalizeSpaceChars = formattedLocation?.toString().replace(/\u00A0/g, ' ');
  const locationObject = normalizeSpaceChars?.split(' - ');

  return {
    country,
    postalCode: locationObject[0]?.trim(),
    town: locationObject[1]?.trim(),
  };
};
