import { CURRENT_BRAND } from 'constants/brand';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const fetchSimulation = async (price?: number) => {
  try {
    if (!price) throw new Error('Missing price');
    const { data } = await apiClient({
      method: 'get',
      params: {
        creditAmount: price,
        fields: 'FULL',
      },
      url: `buyway/${CURRENT_BRAND}/simulations`,
      useBaseUrl: true,
    });

    return data;
  } catch (error) {
    logErrorResponse('Buyway Connector', error, 'Error fetching simulation');
    throw error;
  }
};

const submitBuywayPaymentMode = async ({
  cartCode,
  paymentOption,
  transactionType,
}: {
  cartCode?: string;
  paymentOption?: number;
  transactionType?: string;
}) => {
  try {
    await apiClient({
      method: 'post',
      params: {
        code: cartCode,
        paymentOption,
        transactionType,
      },
      url: `buyway/${CURRENT_BRAND}/paymentInfo`,
      useBaseUrl: true,
    });
  } catch (error) {
    logErrorResponse('Buyway Connector', error, `Error submitting payment mode ${paymentOption}`);
    throw error;
  }
};

const submitBuywayCallback = async ({ callbackId, locale }: { callbackId?: string; locale?: string }) => {
  try {
    if (!callbackId || !locale) throw new Error('Missing callbackId or locale');
    const { data } = await apiClient({
      method: 'post',
      url: `buyway/${CURRENT_BRAND}/payment/callback/${callbackId}/${locale}`,
      useBaseUrl: true,
    });

    return data;
  } catch (error) {
    logErrorResponse('Buyway Connector', error, 'Error submitting buyway callback');
    throw error;
  }
};

export { fetchSimulation, submitBuywayCallback, submitBuywayPaymentMode };
