import {
  FetchQuerySuggestionsParams,
  FetchRefinementValuesParams,
  FetchSearchSuggestionsParams,
  FetchSortsParams,
  SearchProductsParams,
} from './connector';

export const searchKeys = {
  algoliaApiKey: () => ['algoliaApiKey'],
  isIpWhiteListed: () => ['isIpWhiteListed'],
  querySuggestions: (params: FetchQuerySuggestionsParams) => ['querySuggestions', params],
  refinementValues: (params: FetchRefinementValuesParams) => ['refinementValues', params],
  search: (params: SearchProductsParams) => ['searchProducts', params],
  searchSuggestions: (params: FetchSearchSuggestionsParams) => ['searchSuggestions', params],
  sortValues: (params: FetchSortsParams) => ['sortValues', params],
  userIpAddress: () => ['userIpAddress'],
};
