import { apiClient } from 'helpers/ApiClient';
import { BundleAlternativeProducts, BundleList, BundlePrice } from 'types/Bundle';
import { logErrorResponse } from 'utils/loggerUtil';

export type FetchBundlesParams = {
  locale: string;
  productCode: string;
};

const fetchBundles = async ({ locale, productCode }: FetchBundlesParams) => {
  try {
    const { data } = await apiClient<BundleList>({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/bundle/${productCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Bundle Connector', error, `Error fetching bundle for product with code ${productCode}`);
    throw error;
  }
};

export type FetchBundlePriceParams = {
  baseProductCode: string;
  bundleCode: string;
  locale: string;
  selectedAccessoriesProductCodes?: string[];
};

const fetchBundlePrice = async ({
  baseProductCode,
  bundleCode,
  locale,
  selectedAccessoriesProductCodes,
}: FetchBundlePriceParams) => {
  try {
    const { data } = await apiClient<BundlePrice>({
      params: {
        baseProduct: baseProductCode,
        bundleCode,
        fields: 'FULL',
        lang: locale,
        selectedAccessories: selectedAccessoriesProductCodes?.join(','),
      },
      url: `/bundle/price`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Bundle Connector', error, `Error fetching price for bundle with code ${bundleCode}`);
    throw error;
  }
};

export type FetchBundleAlternativeProductsParams = {
  locale: string;
  partCode: string;
  productCode: string;
};

const fetchBundleAlternativeProducts = async ({
  locale,
  partCode,
  productCode,
}: FetchBundleAlternativeProductsParams) => {
  try {
    const { data } = await apiClient<BundleAlternativeProducts>({
      params: {
        fields: 'FULL',
        lang: locale,
        partCode,
        productCode,
      },
      url: `/bundle/alternatives`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'Bundle Connector',
      error,
      `Error fetching alternative products for bundle with product code ${productCode} and part code ${partCode}`,
    );
    throw error;
  }
};

export { fetchBundleAlternativeProducts, fetchBundlePrice, fetchBundles };
