import { apiClient } from 'helpers/ApiClient';
import { FacetSearchResult } from 'types/Facet';
import { PcConfiguration, PcConfigurationsResponse } from 'types/PcConfiguration';
import { logErrorResponse } from 'utils/loggerUtil';

export type FetchConfigurationParams = {
  configurationUid: string;
  locale: string;
};

const fetchConfiguration = async ({ configurationUid, locale }: FetchConfigurationParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      params: {
        lang: locale,
      },
      url: `/configurator/${configurationUid}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, `Error fetching configuration with id ${configurationUid}`);
    throw error;
  }
};

export type FetchConfigurationsParams = {
  locale: string;
};

const fetchConfigurations = async ({ locale }: FetchConfigurationsParams) => {
  try {
    const { data } = await apiClient<PcConfigurationsResponse>({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/configurator/all`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching configurations');
    throw error;
  }
};

export type FetchCustomConfigurationParams = {
  locale: string;
};

const fetchCustomConfiguration = async ({ locale }: FetchCustomConfigurationParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/configurator/custom`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching custom configurations');
    throw error;
  }
};

export type FetchPartProductsParams = {
  configurationCode?: string;
  currentPage?: number;
  filterQuery?: string;
  groups?: string[];
  locale: string;
  pageSize?: number;
  partCode: string;
  sort?: string;
};

const fetchPartProducts = async ({
  configurationCode,
  currentPage,
  filterQuery,
  groups,
  locale,
  pageSize,
  partCode,
  sort,
}: FetchPartProductsParams) => {
  const params = {
    currentPage,
    fields: 'FULL',
    lang: locale,
    pageSize,
    pcConfiguration: configurationCode,
    pcConfigurationPart: partCode,
    query: filterQuery,
    selectedGroups: groups,
    sort,
  };

  try {
    const { data } = await apiClient<FacetSearchResult>({
      params,
      url: `/configurator/parts/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching part products');
    throw error;
  }
};

export type FetchUserFavoriteConfigurationsParams = {
  currentPage?: number;
  locale: string;
  pageSize?: number;
};

const fetchUserFavoriteConfigurations = async ({
  currentPage,
  locale,
  pageSize,
}: FetchUserFavoriteConfigurationsParams) => {
  try {
    const { data } = await apiClient<PcConfigurationsResponse>({
      params: {
        currentPage,
        fields: 'FULL',
        lang: locale,
        pageSize,
      },
      url: `/users/current/pcconfigurations`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error fetching favorite configurations');
    throw error;
  }
};

export type UpdateConfigurationServiceParams = {
  configurationUid: string;
  isServiceSelected: boolean;
  locale: string;
  serviceCode: string;
};

const updateConfigurationService = async ({
  configurationUid,
  isServiceSelected,
  locale,
  serviceCode,
}: UpdateConfigurationServiceParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        lang: locale,
        selected: isServiceSelected,
        serviceCode,
      },
      url: `/configurator/service/${serviceCode}/update`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error updating  service for configuration with id ${configurationUid}`,
    );
    throw error;
  }
};

export type SubmitExtraWishesParams = {
  configurationUid: string;
  locale: string;
  wishes: string;
};

const submitExtraWishes = async ({ configurationUid, locale, wishes }: SubmitExtraWishesParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        fields: 'FULL',
        lang: locale,
        remarks: wishes,
      },
      url: `/configurator/update`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, 'Error submitting extra wishes');
    throw error;
  }
};

export type ChangeConfigurationPartParams = {
  configurationUid: string;
  locale: string;
  partCode: string;
  productCode?: string;
};

const changeConfigurationPart = async ({
  configurationUid,
  locale,
  partCode,
  productCode,
}: ChangeConfigurationPartParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        fields: 'FULL',
        lang: locale,
        partCode,
        productCode,
      },
      url: '/configurator/update',
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error changing  part from configuration with id ${configurationUid}`,
    );
    throw error;
  }
};

export type CreateConfigurationParams = {
  configurationCode: string;
  isCustomConfig: boolean;
  locale: string;
};

const createConfiguration = async ({ configurationCode, isCustomConfig, locale }: CreateConfigurationParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode,
        custom: isCustomConfig,
        fields: 'FULL',
        lang: locale,
      },
      url: `/configurator/create`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error creating configuration with code ${configurationCode}`,
    );
    throw error;
  }
};

const saveFavoriteConfiguration = async (configurationUid: string) => {
  try {
    const { data } = await apiClient<void>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
      },
      url: `/configurator/favorite`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, `Error saving configuration with id ${configurationUid}`);
    throw error;
  }
};

const deleteFavoriteConfiguration = async (configurationUid: string) => {
  try {
    const { data } = await apiClient<void>({
      method: 'delete',
      params: {
        configurationCode: configurationUid,
      },
      url: `/configurator/favorite`,
    });

    return data;
  } catch (error) {
    logErrorResponse(
      'PC Configuration Connector',
      error,
      `Error deleting favorite configuration with id ${configurationUid}`,
    );
    throw error;
  }
};

export type ResetConfigurationParams = {
  configurationUid: string;
  locale: string;
};

const resetConfiguration = async ({ configurationUid, locale }: ResetConfigurationParams) => {
  try {
    const { data } = await apiClient<PcConfiguration>({
      method: 'post',
      params: {
        configurationCode: configurationUid,
        lang: locale,
      },
      url: `/configurator/reset`,
    });

    return data;
  } catch (error) {
    logErrorResponse('PC Configuration Connector', error, `Error resetting configuration with id ${configurationUid}`);
    throw error;
  }
};

export {
  changeConfigurationPart,
  createConfiguration,
  deleteFavoriteConfiguration,
  fetchConfiguration,
  fetchConfigurations,
  fetchCustomConfiguration,
  fetchPartProducts,
  fetchUserFavoriteConfigurations,
  resetConfiguration,
  saveFavoriteConfiguration,
  submitExtraWishes,
  updateConfigurationService,
};
