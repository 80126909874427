import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { log } from 'utils/loggerUtil';
import { buywayKeys } from '.';
import { fetchSimulation, submitBuywayCallback } from './connector';

const useBuywaySimulation = ({ enabled = true, price }: { enabled?: boolean; price?: number }) =>
  useQuery({
    enabled,
    queryFn: () => fetchSimulation(price),
    queryKey: buywayKeys.buywaySimulation(price),
  });

const useBuywayCallback = ({ callbackId, locale }: { callbackId?: string; locale?: string }) => {
  const router = useRouter();
  return useQuery({
    enabled: !!callbackId,
    queryFn: async () => {
      const { data } = await submitBuywayCallback({ callbackId, locale });
      const redirect = new URL(data?.url) || false;
      if (redirect) {
        router.push(redirect.pathname + redirect.search);
      } else {
        log('pages/buyway/hifi/callback/[...id]', 'Something went wrong communicating with the BE', data);
      }
    },
    queryKey: buywayKeys.buywayCallback(callbackId, locale),
  });
};

export { useBuywayCallback, useBuywaySimulation };
