import { FetchBundleAlternativeProductsParams, FetchBundlePriceParams, FetchBundlesParams } from './connector';

export * from './queries';

export const bundleKeys = {
  bundlePartProductAlternatives: (params: FetchBundleAlternativeProductsParams) => [
    'bundlePartProductAlternatives',
    params,
  ],
  bundlePrice: (params: FetchBundlePriceParams) => ['bundlePrice', params],
  bundles: (params: FetchBundlesParams) => ['bundles', params],
};
