import config from 'config';
import { apiClient } from 'helpers/ApiClient';
import { useAuthStore } from 'store/auth';
import { Location } from 'types/Location';
import { Store } from 'types/Store';
import { User } from 'types/User';
import Cookies from 'universal-cookie';
import { logErrorResponse } from 'utils/loggerUtil';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface UserState {
  anonymousFavouriteStores: string[];
  isLoading: boolean;
  user: User | undefined;
}

export interface UserStore extends UserState {
  actions: {
    addFavouriteStore: (storeId?: string) => Promise<void>;
    getFavouriteStores: () => Store[];
    removeFavouriteStore: (storeId?: string) => Promise<void>;
    setUser: (user: User | undefined) => void;
    updateCurrentLocation: (location: Location | null, isAnonymous: boolean) => void;
  };
}

const initialState: UserState = {
  anonymousFavouriteStores: new Cookies().get(config.favouriteStoreCookie.key) || [],
  isLoading: false,
  user: undefined,
};

export const useUserStore = create<UserStore>()(
  devtools(
    (set, get) => ({
      ...initialState,
      actions: {
        addFavouriteStore: async (storeId) => {
          set(() => ({ isLoading: true }));
          const anonymous = useAuthStore.getState().anonymous;

          if (anonymous) {
            const cookieKey = config.favouriteStoreCookie.key;
            const cookieStores = new Cookies().get(cookieKey) || [];
            const updatedFavoriteStores = [...cookieStores, storeId];
            new Cookies().set(cookieKey, updatedFavoriteStores, config.cookie);
            set(() => ({ anonymousFavouriteStores: updatedFavoriteStores }));
          } else {
            try {
              const { data: user } = await apiClient<User>({
                method: 'POST',
                params: {
                  fields: 'FULL',
                  store: storeId,
                },
                url: `/users/current/store`,
              });
              set(() => ({ user }));
            } catch (error) {
              logErrorResponse('removeFavouriteStore', error, 'Failed to remove favourite store.');
            }
          }
          set(() => ({ isLoading: false }));
        },
        getFavouriteStores: () => {
          const anonymous = useAuthStore.getState().anonymous;

          if (anonymous) {
            return get().anonymousFavouriteStores;
          }

          const favouriteStores = get().user?.favouriteStores;

          if (!favouriteStores) {
            return [];
          }

          return favouriteStores?.reduce((prev: string[], curr: Store) => {
            if (curr.krefelId) {
              return [...prev, curr.krefelId];
            }

            return prev;
          }, []);
        },
        removeFavouriteStore: async (storeId) => {
          set(() => ({ isLoading: true }));
          const anonymous = useAuthStore.getState().anonymous;

          if (anonymous) {
            const cookieKey = config.favouriteStoreCookie.key;

            const cookiesStores: string[] = new Cookies().get(cookieKey);
            const filteredStores = cookiesStores.filter((id) => id !== storeId);

            if (filteredStores?.length) {
              new Cookies().set(cookieKey, filteredStores, config.cookie);
              set(() => ({ anonymousFavouriteStores: filteredStores }));
            } else {
              new Cookies().remove(cookieKey, config.cookie);
              set(() => ({ anonymousFavouriteStores: [] }));
            }
          } else {
            try {
              const { data: user } = await apiClient<User>({
                method: 'DELETE',
                params: {
                  fields: 'FULL',
                  store: storeId,
                },
                url: `/users/current/store`,
              });
              set(() => ({ user }));
            } catch (error) {
              logErrorResponse('removeFavouriteStore', error, 'Failed to remove favourite store.');
            }
          }
          set(() => ({ isLoading: false }));
        },
        setUser: (user) => set(() => ({ user })),
        updateCurrentLocation: async (location, isAnonymous = false) => {
          const userId = isAnonymous ? 'anonymous' : 'current';
          const town = location?.name || null;
          const postalCode = location?.postalCode || null;
          const country = location?.country || null;

          try {
            const { data: user } = await apiClient<User>({
              method: 'POST',
              params: {
                country,
                fields: 'FULL',
                location: town,
                postalCode,
              },
              url: `/users/${userId}/location`,
            });

            useUserStore.getState().actions.setUser(user);

            if (location) {
              new Cookies().set(config.locationCookie.key, location, config.cookie);
            } else {
              new Cookies().remove(config.locationCookie.key);
            }
          } catch (error) {
            logErrorResponse('updateCurrentLocation', error, 'Failed to update current location.');
          }
        },
      },
    }),
    { name: 'userStore' },
  ),
);

export const useUser = () => useUserStore((state) => state.user);
export const useAnonymousFavouriteStores = () => useUserStore((state) => state.anonymousFavouriteStores);
export const useUserLoading = () => useUserStore((state) => state.isLoading);
export const useUserActions = () => useUserStore((state) => state.actions);
