import config from 'config';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import { checkValidityOfAuthToken, getOAuthCookie } from 'helpers/OAuth2Helper';
import { NextRouter } from 'hooks/useRouter';
import { pathnames } from 'i18n/pathnames';
import { GetServerSidePropsContext } from 'next';
import { Dispatch } from 'redux';
import { clearCart } from 'redux/modules/cart';
import { clearNewCheckoutInfo } from 'redux/modules/checkout';
import { useAuthStore } from 'store/auth';
import { useNotificationStore } from 'store/notification';
import { useUserStore } from 'store/user';
import { Credentials, OAuthToken } from 'types/Auth';
import { User } from 'types/User';
import Cookies from 'universal-cookie';
import { log } from 'utils/loggerUtil';
import { BRAND_VALUES } from '../constants/brand';
import { GRANT_TYPES, SOCIAL_TYPES, SocialLoginType } from '../constants/oauth';
import { createLocationObject } from './myAccountUtil';
import { createNotification } from './notifications';

const { platform } = config;

export const prefixUserCredentials = (credentials: Credentials) => {
  const shouldPrefix = platform !== BRAND_VALUES.KREFEL.toLocaleLowerCase();
  const brandPrefix = `${platform}_`;

  if (!shouldPrefix) {
    return credentials;
  }

  const { email, uid } = credentials;

  return {
    ...credentials,
    ...(!!email && !email.startsWith(brandPrefix) && { email: `${brandPrefix}${email}` }),
    ...(!!uid && !uid.startsWith(brandPrefix) && { uid: `${brandPrefix}${uid}` }),
  };
};

export const setSalesForceUser = (uid: string) => {
  if (uid && window._etmc) {
    config.salesForce.orgId && window._etmc.push(['setOrgId', config.salesForce.orgId]);
    window._etmc.push(['setUserInfo', { email: uid }]);
    window._etmc.push(['trackPageView']);
  }
};

interface SocialLoginCredentials {
  id: string;
  socialLoginType: SocialLoginType;
  token: string;
}
export const getSocialLoginParameters = ({ id, socialLoginType, token }: SocialLoginCredentials) => {
  switch (socialLoginType) {
    case SOCIAL_TYPES.GOOGLE:
      return {
        googleId: id,
        googleToken: token,
        grant_type: GRANT_TYPES.GOOGLE,
      };
    case SOCIAL_TYPES.FACEBOOK:
      return {
        facebookId: id,
        facebookToken: token,
        grant_type: GRANT_TYPES.FACEBOOK,
      };
    case SOCIAL_TYPES.APPLE:
      return {
        appleId: id,
        appleToken: token,
        grant_type: GRANT_TYPES.APPLE,
      };
    default:
      break;
  }
  return null;
};

export const afterLoginActions = (user: User, showNotification?: boolean) => {
  if (!user) {
    throw new Error('Error when getting the authenticated user');
  }

  if (user?.uid) {
    setSalesForceUser(user.uid);
    if (showNotification) {
      const notificationType = useUserStore.getState().user?.dealerDiscount
        ? NOTIFICATION_TYPES.LOGGED_IN_DEALER_DISCOUNT
        : NOTIFICATION_TYPES.LOGGED_IN;
      const notification = createNotification({ type: notificationType });
      useNotificationStore.getState().actions.queueNotification(notification);
    }
  }
};

export const afterLoginReduxActions = () => {
  const cookies = new Cookies();

  //   Set user location
  const location = cookies.get(config.locationCookie.key);
  if (location?.postalCode && location?.town && location?.country) {
    const locationObject = createLocationObject(location);
    useUserStore.getState().actions.updateCurrentLocation(locationObject, useAuthStore.getState().anonymous);
    cookies.remove(config.locationCookie.key, config.cookie);
  }

  //   Set user favorite stores
  const favouriteStoreIds = cookies.get(config.favouriteStoreCookie.key);
  if (favouriteStoreIds?.length > 0) {
    favouriteStoreIds.map((store: string) => {
      useUserStore.getState().actions.addFavouriteStore(store);
    });

    cookies.remove(config.favouriteStoreCookie.key, config.cookie);
  }
};

export const afterLogoutReduxActions = (dispatch: Dispatch, router: NextRouter) => {
  const { pathname } = router;
  dispatch(clearCart({ clearAnonymousCart: false }));
  dispatch(clearNewCheckoutInfo());
  if (pathname !== pathnames.INDEX) {
    router.push(pathnames.INDEX);
  }
};

export const afterLogoutActions = () => {
  useUserStore.getState().actions.setUser(undefined);
};

export const wrapAuth = async (action: () => Promise<unknown>) =>
  new Promise((resolve, reject) => {
    const authCookie = getOAuthCookie();

    if (authCookie) {
      action()
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    } else {
      useAuthStore
        .getState()
        .actions.authenticateAnonymous()
        .then(() =>
          action()
            .then((result) => resolve(result))
            .catch((error) => reject(error)),
        )
        .catch((error) => reject(error));
    }
  });

export const getServerSideAuthenticated = async ({ req }: GetServerSidePropsContext) => {
  try {
    const oauthCookie = req.cookies[config.oauth.key];
    if (!oauthCookie) {
      throw new Error('No OAuth cookie found');
    }
    const oauth = JSON.parse(oauthCookie) as OAuthToken;
    const accessToken = oauth.access_token;
    const authenticatedUser = await checkValidityOfAuthToken(accessToken);
    return !!authenticatedUser;
  } catch (error) {
    log('getServerSideAuthenticated', `Something went wrong when checking server side authenticated user`, error);
    return false;
  }
};
