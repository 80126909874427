import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { useRouterParam } from 'hooks/useRouterParam';
import { pcConfiguratorKeys } from '.';
import { SORT_TYPES } from '../../constants/pcConfigurator';
import {
  fetchConfiguration,
  fetchConfigurations,
  fetchCustomConfiguration,
  fetchPartProducts,
  fetchUserFavoriteConfigurations,
} from './connector';

type UseConfigurationParams = {
  configurationUid: string;
  enabled?: boolean;
};

const useConfiguration = ({ configurationUid, enabled = true }: UseConfigurationParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: enabled && !!configurationUid,
    queryFn: () => fetchConfiguration({ configurationUid, locale }),
    queryKey: pcConfiguratorKeys.configuration({ configurationUid, locale }),
  });
};

const useConfigurations = () => {
  const { locale } = useRouter();
  return useQuery({
    queryFn: () => fetchConfigurations({ locale }),
    queryKey: pcConfiguratorKeys.configurations({ locale }),
  });
};

const useCustomConfigurations = () => {
  const { locale } = useRouter();
  return useQuery({
    queryFn: () => fetchCustomConfiguration({ locale }),
    queryKey: pcConfiguratorKeys.customConfiguration({ locale }),
  });
};

type UsePartProductsParams = {
  configurationCode?: string;
  currentPage?: number;
  filterQuery?: string;
  groups?: string[];
  pageSize?: number;
  partCode: string;
  sort?: string;
};

const usePartProducts = ({
  configurationCode,
  currentPage = 0,
  filterQuery,
  groups,
  pageSize = 24,
  partCode,
  sort = SORT_TYPES.RELEVANCE,
}: UsePartProductsParams) => {
  const { locale } = useRouter();
  const configurationCodeParam = useRouterParam('id');
  const partCodeParam = useRouterParam('part');

  const enrichedParams = {
    configurationCode: configurationCode ?? configurationCodeParam,
    currentPage,
    filterQuery,
    groups,
    locale,
    pageSize,
    partCode: partCode ?? partCodeParam,
    sort,
  };

  return useQuery({
    queryFn: () => fetchPartProducts(enrichedParams),
    queryKey: pcConfiguratorKeys.partProducts(enrichedParams),
  });
};

const useUserFavoriteConfigurations = ({ currentPage = 0, pageSize = 3 }) => {
  const { locale } = useRouter();
  return useQuery({
    queryFn: () => fetchUserFavoriteConfigurations({ currentPage, locale, pageSize }),
    queryKey: pcConfiguratorKeys.userFavoriteConfigurations({ currentPage, locale, pageSize }),
  });
};

export { useConfiguration, useConfigurations, useCustomConfigurations, usePartProducts, useUserFavoriteConfigurations };
