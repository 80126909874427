import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { bundleKeys } from '.';
import { fetchBundleAlternativeProducts, fetchBundlePrice, fetchBundles } from './connector';

type UseBundlesParams = {
  productCode: string;
};

const useBundles = ({ productCode }: UseBundlesParams) => {
  const { locale } = useRouter();

  return useQuery({
    enabled: !!productCode,
    queryFn: () => fetchBundles({ locale, productCode }),
    queryKey: bundleKeys.bundles({ locale, productCode }),
    select: (data) => data?.bundles,
  });
};

type UseBundlePriceParams = {
  baseProductCode: string;
  bundleCode: string;
  selectedAccessoriesProductCodes: string[];
};

const useBundlePrice = ({ baseProductCode, bundleCode, selectedAccessoriesProductCodes }: UseBundlePriceParams) => {
  const { locale } = useRouter();

  return useQuery({
    queryFn: () => fetchBundlePrice({ baseProductCode, bundleCode, locale, selectedAccessoriesProductCodes }),
    queryKey: bundleKeys.bundlePrice({ baseProductCode, bundleCode, locale, selectedAccessoriesProductCodes }),
  });
};

type UseBundleAlternativeProducts = {
  enabled?: boolean;
  partCode: string;
  productCode: string;
};

const useBundleAlternativeProducts = ({ enabled = false, partCode, productCode }: UseBundleAlternativeProducts) => {
  const { locale } = useRouter();

  return useQuery({
    enabled,
    queryFn: () => fetchBundleAlternativeProducts({ locale, partCode, productCode }),
    queryKey: bundleKeys.bundlePartProductAlternatives({ locale, partCode, productCode }),
    select: (data) => data?.products,
  });
};

export { useBundleAlternativeProducts, useBundlePrice, useBundles };
