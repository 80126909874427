import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import shared from 'theme/shared';
import brandFonts from './fonts';
import { Z_INDICES } from './indices';
import { defaultTheme } from './themes';

interface GlobalStyleProps {
  isBodyScrollable?: boolean;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
${normalize()}
${brandFonts}
${shared}

body {
  font-family: ${defaultTheme?.fontFamily?.primary} !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;

  &.no-padding {
    padding-top: 0;
  }

  &.checkout {
    padding-top: 0;
  }
}

html {
  scroll-behavior: auto !important;
}

// Only solution to fix z-index of bootstrap modal
// Not able to use theme provider here
.modal-backdrop {
  z-index: ${Z_INDICES.BACKDROP};
}

// Overwrite flix z-indices
#flix_hotspots.flix_hotspotV2 {
    z-index: calc(${Z_INDICES.HEADER_UNDERWRITE} - 2) !important;
}

.share-bubble {
  z-index: calc(${Z_INDICES.HEADER_UNDERWRITE} - 1) !important;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.row-full-height {
  height: 100%;
}

// LEGACY CODE
.fw {
  display: inline-block;
  width: 100%;
}

.vert-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

summary::-webkit-details-marker {
  display: none;
}

`;
