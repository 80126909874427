import { VIMEO_REGEX, YOURUBE_REGEX } from 'constants/media';
import { fetchVimeoVideoData } from 'features/cms/connector';

export const getYoutubeVideoIdFromUrl = (url: string) => {
  const regex =
    /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;

  const match = url.match(regex);

  return Array.isArray(match) && match.length > 7 ? match?.[7]: null;
};

export const getYoutubeThumbnailUrl = (url: string) => {
  const videoId = getYoutubeVideoIdFromUrl(url);

  if (!videoId) return null;

  return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
};

export const fetchThumbnailUrl = async (videoUrl: string) => {
  const isYoutube = YOURUBE_REGEX.test(videoUrl);
  const isVimeo = VIMEO_REGEX.test(videoUrl);

  if (isVimeo) {
    const result = await fetchVimeoVideoData(videoUrl);
    return result?.thumbnail_url;
  } else if (isYoutube) {
    return getYoutubeThumbnailUrl(videoUrl);
  }

  return null;
};
